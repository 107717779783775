/* eslint-disable prefer-rest-params */
import { Conversion, DecimalValues, Formatter, FormatterValues } from "./types";
import formatters, { formatterValues } from "./formatters";

// 1 deten = 24 hours
// 1 ten = 2.4 hours
// 1 den = 0.24 hours
// 1 cen = 0.024 hours = 86.4 seconds

type KeyOfFormatters = keyof typeof formatters;

// 1 micen = 0.000024 hours = 0.0864 seconds = 86.4 milliseconds
const RATE_TO_MILLISEC = 86400;
// const OFFSET = 1648764000000; // Decimal date counting was born on 2022-04-01
const OFFSET = -62167219200000; // 0000-01-01
class TDTS {
  values: DecimalValues = {
    _originalTimestamp: 0,
    decimalstamp: 0,
    kiten: 0,
    heten: 0,
    deten: 0,
    ten: 0,
    den: 0,
    cen: 0,
    micen: 0,
    muecen: 0,
    nacen: 0,
  };

  constructor(
    year?: Conversion["year"] | Conversion | string,
    month?: Conversion["month"],
    day?: Conversion["day"],
    hour?: Conversion["hour"],
    minute?: Conversion["minute"],
    second?: Conversion["second"]
  ) {
    let args: undefined | number[];

    if (typeof year === "string") {
      args = [new Date(year).getTime()];
    } else if (typeof year === "object") {
      const newArgs = (
        [
          "year",
          "month",
          "day",
          "hour",
          "minute",
          "second",
        ] as (keyof Conversion)[]
      ).map((key) => year[key]);
      let index = newArgs.length;

      while (index-- && !newArgs[index]);

      args = newArgs.splice(0, index + 1).map((arg) => arg ?? 0);
    } else {
      args = arguments.length ? Array.from(arguments) : undefined;
    }

    const isMillis = Math.abs(args?.[0] ?? 0) > 10000;

    this.values = this.convertMillisecToTDTS(
      isMillis
        ? args?.[0] ?? 0
        : new (Function.prototype.bind.apply(Date, [
            null,
            ...(args ?? []),
          ]))().getTime()
    );
  }

  convertToNegativTDTS(details: DecimalValues): DecimalValues {
    return Object.entries(details).reduce<DecimalValues>((acc, [key, val]) => {
      switch (key) {
        case "kiten": {
          return {
            ...acc,
            [key]: -Math.abs(val) + 1,
          };
        }
        case "heten":
        case "deten":
          return {
            ...acc,
            [key]: 10 - Math.abs(val) + 1,
          };
        case "ten":
        case "den":
        case "cen": {
          return {
            ...acc,
            [key]: 9 - Math.abs(val),
          };
        }
        case "micen":
        case "muecen":
        case "nacen": {
          return {
            ...acc,
            [key]: 999 - Math.abs(val),
          };
        }
        default:
          return acc;
      }
    }, details);
  }

  convertMillisecToTDTS(millisec: number): DecimalValues {
    const decimalstamp = ((millisec - OFFSET) * 10) / (RATE_TO_MILLISEC * 10);
    const [cens, micens = "0"] = `${decimalstamp}`.split(".");
    const roundedMicens = micens.padEnd(9, "0").substring(0, 9);
    const micen = roundedMicens.substring(0, 3);
    const muecen = roundedMicens.substring(3, 6);
    const nacen = roundedMicens.substring(6);
    const [dens, cen = "0"] = `${Number(cens) / 10}`.split(".");
    const [tens, den = "0"] = `${Number(dens) / 10}`.split(".");
    const [detens, ten = "0"] = `${Number(tens) / 10}`.split(".");
    const [hetens, deten = "0"] = `${Number(detens) / 10}`.split(".");
    const [kiten, heten = "0"] = `${Number(hetens) / 10}`.split(".");

    const details = {
      _originalTimestamp: millisec,
      decimalstamp: Number(decimalstamp),
      kiten: Number(kiten) + 1,
      heten: Number(heten) + 1,
      deten: Number(deten) + 1,
      ten: Number(ten),
      den: Number(den),
      cen: Number(cen),
      micen: Number(micen),
      muecen: Number(muecen),
      nacen: Number(nacen),
    };

    if (millisec - OFFSET >= 0) {
      return details;
    }

    return this.convertToNegativTDTS(details);
  }

  private getFormaters(
    parts?: string[] | null
  ): Record<FormatterValues, Formatter> {
    parts?.sort((a, b) => {
      if (a.length === b.length) {
        return 0;
      }

      if (a.length > b.length) {
        return -1;
      }

      return 1;
    });

    return (parts?.reduce((acc, key) => {
      if (!formatters[key as KeyOfFormatters]) {
        return { ...acc, [key]: () => key };
      }

      return { ...acc, [key]: formatters[key as KeyOfFormatters] };
    }, {}) ?? {}) as Record<FormatterValues, Formatter>;
  }

  private toFormatted(format = "KKKK-H-D t:d:c.mmm.uuu.nnn") {
    const parts = format.match(/([\w]+)/g);
    const formatterFunctions = this.getFormaters(parts);
    return format.replace(/([\w]+)/g, (_, m) => {
      const formatterValue = m as FormatterValues;
      if (formatterFunctions[formatterValue]) {
        return formatterFunctions[formatterValue](
          this.values[formatterValues[formatterValue]]
        );
      }
      return "";
    });
  }

  getTime() {
    return this.values.decimalstamp;
  }

  toString(format?: string) {
    return this.toFormatted(format);
  }
}

export default TDTS;
