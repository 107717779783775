import React from "react";
import styled from "styled-components";
import { ReactComponent as ClockPaths } from "./clock.svg";

const ClockWrapper = styled.div`
  margin: auto;
  height: 246.6px;
  width: 246.6px;
  pointer-events: none;
  position: relative;

  &:nth-child(2) {
    @media (max-width: 600px) {
      grid-row: 1;
    }
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(211, 67, 128);
  background: linear-gradient(
    120deg,
    rgba(211, 67, 128, 1) 0%,
    rgba(243, 177, 78, 1) 100%
  );
  clip-path: url(#bg);
`;

const Digits = styled(Background)<{ type: "normal" | "decimal" }>`
  clip-path: url(#digits-${({ type }) => type});
`;

// const rotate = (degFrom: number, degTo: number) => keyframes`
//   from {
//       transform: rotate(${degFrom}deg);
//   }
//   to {
//       transform: rotate(${degTo}deg);
//   }
// `;

const Tick = styled(Background)<{
  // degFrom: number;
  // degTo: number;
  // duration: number;
}>``;

const Tick1 = styled(Tick)`
  clip-path: url(#tick1);
`;
const Tick2 = styled(Tick)`
  clip-path: url(#tick2);
`;
const Tick3 = styled(Tick)`
  clip-path: url(#tick3);
`;
const Tick4 = styled(Tick)`
  clip-path: url(#tick4);
`;

const Ticks = [Tick1, Tick2, Tick3, Tick4];

const HiddenClockPaths = styled(ClockPaths)`
  width: 0;
  height: 0;
  position: absolute;
  left: -10000px;
`;

type Props3Tick = {
  ticks: 3;
  durations: [number, number, number];
  currents: [number, number, number];
  type: "normal";
};

type Props4Tick = {
  ticks: 4;
  durations: [number, number, number, number];
  currents: [number, number, number, number];
  type: "decimal";
};

const Clock = ({
  ticks,
  // durations,
  currents,
  type,
}: Props3Tick | Props4Tick) => {
  return (
    <ClockWrapper>
      <HiddenClockPaths />
      <Background />
      <Digits type={type} />
      {Ticks.slice(0, ticks).map((TickComponent, index) => {
        return (
          <TickComponent
            key={index}
            style={{ transform: `rotate(${currents[index]}deg)` }}
            // duration={durations[index]}
            // degFrom={currents[index]}
            // degTo={currents[index] + 360}
          />
        );
      })}
    </ClockWrapper>
  );
};

export default Clock;
