export const maxValues = {
  normal: {
    second: 60,
    minute: 60,
    hour: 12,
  },
  decimal: {
    micen: 1000,
    cen: 10,
    den: 10,
    ten: 10,
  },
};
