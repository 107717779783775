import { format } from "date-fns";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { createGlobalStyle } from "styled-components";
import Clock from "./Clock";
import { maxValues } from "./contants";
import TDTS from "./tdts/tdds";
// import logo from "./logo.png";
// import bg from "./logo.png";

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'digital-7_monomono';
    src: url('/fonts/digital-7_mono-webfont.woff2') format('woff2'),
        url('/fonts/digital-7_mono-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

  }
`;

const Main = styled.div`
  width: 100%;
  text-align: center;
  min-height: 100vh;
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Digit = styled.div`
  text-align: left;
  font-family: "digital-7_monomono", Helvetica;
  font-size: 1em;
  line-height: 1em;
  overflow: auto;
  overflow-wrap: anywhere;
  transition: font-size 0.2s;

  @media (max-width: 700px) {
    font-size: 0.8em;
    line-height: 0.8em;
  }
`;

const Container = styled.div`
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: Helvetica;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 10px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  // @media (max-width: 1000px) {
  //   grid-template-columns: 1fr;
  // }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  & > hr {
    min-height: 20px;
    color: transparent;
    border: none;

    &:nth-child(6) {
      @media (max-width: 600px) {
        grid-row: 3;
      }
    }
  }

  & > ${Digit} {
    &:nth-child(4) {
      @media (max-width: 600px) {
        grid-row: 2;
      }
    }
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: 1em;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  width: 100%;

  &:nth-child(10) {
    @media (max-width: 600px) {
      grid-row: 5;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #535964;
    }
  }

  & > hr {
    min-height: 20px;
    color: transparent;
    border: none;
  }

  // @media (max-width: 550px) {
  //   grid-template-columns: 1fr;
  // }

  // &:nth-child(1) {
  //   @media (max-width: 1000px) {
  //     grid-row: 3;
  //   }
  // }

  // &:nth-child(2) {
  //   @media (max-width: 1000px) {
  //     grid-row: 2;
  //   }
  // }
`;

const Title = styled.div`
  font-size: 1.1em;
  font-weight: bold;

  &:nth-child(8) {
    @media (max-width: 600px) {
      grid-row: 4;
    }
  }
`;

const Label = styled.div`
  text-align: right;
  font-size: 1em;
  line-height: 1em;
  transition: font-size 0.2s;

  @media (max-width: 700px) {
    font-size: 0.8em;
    line-height: 0.8em;
  }
`;

// const www = window as Record<string, any>;
// www.TDTS = TDTS;

// console.log(new TDTS({ day: 1, minute: 12 }).toString());
// console.log(new TDTS("0000-01-01T00:00:02").toString());

function App() {
  const now = useMemo(() => Date.now(), []);
  const [millis, setMillis] = useState(now);
  const [deciMillis, setDeciMillis] = useState(now);
  const milliCounter = useRef(0);

  useEffect(() => {
    setInterval(() => {
      if (milliCounter.current % 1000 === 0) {
        setMillis(Date.now());
      }

      setDeciMillis(Date.now());
    }, 100);
  }, []);

  const currentTime = useMemo(() => new Date(millis), [millis]);
  const currentDecimalTime = useMemo(() => new TDTS(deciMillis), [deciMillis]);

  const currentTimeData = useMemo(() => {
    return {
      year: currentTime.getFullYear(),
      month: currentTime.getMonth() + 1,
      day: currentTime.getDate(),
      hour: currentTime.getHours(),
      minute: currentTime.getMinutes(),
      second: currentTime.getSeconds(),
      millisecond: currentTime.getMilliseconds(),
    };
  }, [currentTime]);

  const getCurrentPoint = useCallback(
    (max: number, current: number) => (360 / max) * current,
    []
  );

  const getCurrentDate = useCallback(
    (date: Date, dateFormat = "yyyy-MM-dd"): string => {
      return format(date, dateFormat);
    },
    []
  );
  const getcurrentTime = useCallback(
    (date: Date, dateFormat = "HH:mm:ss"): string => {
      return format(date, dateFormat);
    },
    []
  );

  return (
    <Main>
      <FontStyles />
      <Container>
        <Clock
          ticks={3}
          durations={[60, 3600, 43200]}
          currents={[
            getCurrentPoint(maxValues.normal.second, currentTimeData.second),
            getCurrentPoint(maxValues.normal.minute, currentTimeData.minute) +
              getCurrentPoint(maxValues.normal.second, currentTimeData.second) /
                60,
            getCurrentPoint(maxValues.normal.hour, currentTimeData.hour % 12) +
              getCurrentPoint(maxValues.normal.minute, currentTimeData.minute) /
                12 +
              getCurrentPoint(maxValues.normal.second, currentTimeData.second) /
                60 /
                12,
          ]}
          type="normal"
        />
        <Clock
          ticks={4}
          durations={[86.4, 864, 8640, 86400]}
          currents={[
            getCurrentPoint(
              maxValues.decimal.micen,
              currentDecimalTime.values.micen
            ),
            getCurrentPoint(
              maxValues.decimal.cen,
              currentDecimalTime.values.cen
            ) +
              getCurrentPoint(
                maxValues.decimal.micen,
                currentDecimalTime.values.micen
              ) /
                10,
            getCurrentPoint(
              maxValues.decimal.den,
              currentDecimalTime.values.den
            ) +
              getCurrentPoint(
                maxValues.decimal.cen,
                currentDecimalTime.values.cen
              ) /
                10 +
              getCurrentPoint(
                maxValues.decimal.micen,
                currentDecimalTime.values.micen
              ) /
                10 /
                10,
            getCurrentPoint(
              maxValues.decimal.ten,
              currentDecimalTime.values.ten
            ) +
              getCurrentPoint(
                maxValues.decimal.den,
                currentDecimalTime.values.den
              ) /
                10 +
              getCurrentPoint(
                maxValues.decimal.cen,
                currentDecimalTime.values.cen
              ) /
                10 /
                10 +
              getCurrentPoint(
                maxValues.decimal.micen,
                currentDecimalTime.values.micen
              ) /
                10 /
                10 /
                10,
          ]}
          type="decimal"
        />
        <Digit style={{ textAlign: "center" }}>
          {getcurrentTime(currentTime)}
        </Digit>
        <Digit style={{ textAlign: "center" }}>
          {currentDecimalTime.toString("tt:dd:cc:mm")}
        </Digit>
        <hr />
        <hr />
        <Title>Normal Time</Title>
        <Title>Decimal Time</Title>
        <Wrapper>
          <Label>Date:</Label>
          <Digit>{getCurrentDate(currentTime)}</Digit>
          <Label>Stamp:</Label>
          <Digit>{millis}</Digit>
          <hr />
          <hr />
          {Object.entries(currentTimeData).map(([name, value], index) => (
            <>
              <Label key={index}>{name}:</Label>
              <Digit>{value}</Digit>
            </>
          ))}
        </Wrapper>
        <Wrapper>
          <Label>Date:</Label>
          <Digit>{currentDecimalTime.toString("KKKK-HH-DD")}</Digit>
          <Label>Stamp:</Label>
          <Digit>{currentDecimalTime.getTime()}</Digit>
          <hr />
          <hr />
          {Object.entries(currentDecimalTime.values)
            .filter(
              ([name]) => !["_originalTimestamp", "decimalstamp"].includes(name)
            )
            .map(([name, value], index) => (
              <>
                <Label key={index}>{name}:</Label>
                <Digit>{value}</Digit>
              </>
            ))}
        </Wrapper>
      </Container>
    </Main>
  );
}

export default App;
