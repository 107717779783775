import { Decimals, Formatter, FormatterValues } from "./types";

export const formatterValues: Record<FormatterValues, Decimals> = {
  KKKK: "kiten",
  KK: "kiten",
  K: "kiten",
  HH: "heten",
  H: "heten",
  D: "deten",
  DD: "deten",
  DDDD: "deten",
  tt: "ten",
  t: "ten",
  dd: "den",
  d: "den",
  cc: "cen",
  c: "cen",
  mmm: "micen",
  mm: "micen",
  m: "micen",
  uuu: "muecen",
  uu: "muecen",
  u: "muecen",
  nnn: "nacen",
  nn: "nacen",
  n: "nacen",
};

const totalLengthValue: Formatter = (value) => value.toString();
const pad3Value: Formatter = (value) =>
  value.toString().padStart(3, "0").substring(0, 3);
const pad2Value: Formatter = (value) =>
  value.toString().padStart(2, "0").substring(0, 2);
const lastCharsValue: Formatter = (value) => {
  const stringValue = value.toString();
  return `${stringValue.substring(stringValue.length - 2, stringValue.length)}`;
};
const first2Value: Formatter = (value) => {
  return value.toString().padStart(3, "0").substring(0, 2);
};

const KKKK = totalLengthValue;
const mmm = pad3Value;
const uuu = pad3Value;
const nnn = pad3Value;
const KK = lastCharsValue;
const K = totalLengthValue;
const HH = pad2Value;
const H = totalLengthValue;
const DD = pad2Value;
const D = totalLengthValue;
const tt = pad2Value;
const t = totalLengthValue;
const dd = pad2Value;
const d = totalLengthValue;
const cc = pad2Value;
const c = totalLengthValue;
const m = totalLengthValue;
const mm = first2Value;
const u = totalLengthValue;
const uu = first2Value;
const n = totalLengthValue;
const nn = first2Value;

const formatters: Partial<Record<FormatterValues, Formatter>> = {
  KKKK,
  mmm,
  uuu,
  nnn,
  KK,
  K,
  HH,
  H,
  DD,
  D,
  dd,
  d,
  tt,
  t,
  cc,
  c,
  mm,
  m,
  uu,
  u,
  nn,
  n,
};

export default formatters;
